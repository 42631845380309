#article {
  .article-date {
    @extend %date;

    margin-top: $standard-space;
  }

  h1, h2, h3 {
    margin: $standard-space 0;
    text-align: left;
  }
}