%fixed-background {
  background-color: transparent;
  background-position: 50% 50%;
  background-repeat: no-repeat;
}

%date {
  display: block;
  font-size: $larger-text-size;
  color: $text-color-dark;
  //margin-left: $standard-space;
}