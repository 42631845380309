#app {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;

  height: 100%;
  width: 100%;
  margin: 0 auto;

  @include breakpoint(sm) {
    width: map-get($breakpoints, sm);
  }
  @include breakpoint(md) {
    width: map-get($breakpoints, md);
  }
  @include breakpoint(lg) {
    width: map-get($breakpoints, lg);
  }
}

#app>header {
  flex-grow: 0;
}

#app>main {
  padding: $standard-space 2*$standard-space;
  flex-grow: 1;
}

#app>footer {
  text-align: center;
  margin: 2rem 0 0 0;
  flex-grow: 0;
}