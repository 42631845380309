@use "sass:math";
@use "sass:list";

@mixin breakpoint($breakpoint) {
  @media (min-width: map-get($breakpoints, $breakpoint)) {
    @content;
  }
}

@mixin transform-origin($x-origin, $y-origin) {
  transform-origin: $x-origin $y-origin;
  -webkit-transform-origin: $x-origin $y-origin;
  -moz-transform-origin: $x-origin $y-origin;
  -ms-transform-origin: $x-origin $y-origin;
  -o-transform-origin: $x-origin $y-origin;
}

@mixin transform-translate($x-translate, $y-translate) {
  transform: translate($x-translate, $y-translate);
  -webkit-transform: translate($x-translate, $y-translate);
  -moz-transform: translate($x-translate, $y-translate);
  -ms-transform: translate($x-translate, $y-translate);
  -o-transform: translate($x-translate, $y-translate);
}

@mixin floating-icon($width, $height, $position, $tweak-x: 0, $tweak-y: 0) {
  content: "";
  position: absolute;
  display: block;
  width: $width;
  height: $height;
  @if $position == left {
    left: 0;
    top: 50%;
    @include transform-origin(50%, 50%);
    margin-left: -$width + $tweak-x;
    margin-top: math.div($height, -2) + $tweak-y;
  } @else if $position == top {
    left: 50%;
    top: 0;
    @include transform-origin(50%, 50%);
    margin-left: math.div($width, -2) + $tweak-x;
    margin-top: -$height + $tweak-y;
  } @else if $position == right {
    right: 0;
    top: 50%;
    @include transform-origin(50%, 50%);
    margin-right: -$width - $tweak-x;
    margin-top: math.div($height, -2) + $tweak-y;
  } @else if $position == bottom {
    left: 50%;
    bottom: 0;
    @include transform-origin(50%, 50%);
    margin-left: math.div($width, -2) + $tweak-x;
    margin-bottom: -$height - $tweak-y;
  } @else {
    // center
    left: 50%;
    top: 50%;
    @include transform-origin(50%, 50%);
    margin-left: math.div($width, -2) + $tweak-x;
    margin-top: math.div($height, -2) + $tweak-y;
  }
}

@mixin link-pointer () {
  &:hover::before {
    @include floating-icon(62px, 44px, left, $tweak-x: 10px);
    @extend %fixed-background;
    background-image: url("./../media/pointer.png");

    @extend %poke-right-animation;
  }
}