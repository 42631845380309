@keyframes poke-right-keyframes {
  from {
    transform: translateX(-50%);
  }

  33% {
    transform: translateX(0);
  }

  to {
    transform: translateX(-50%);
  }
}

%poke-right-animation {
  animation-duration: 1s;
  animation-name: poke-right-keyframes;
  animation-iteration-count: infinite;
}