@use "sass:list";
@use "sass:math";

$menu-margin: 50px;
$header-margin: 160px;

#home-link {
  @extend %fixed-background;
  background-image: url("./../../media/home.png");
  width: 288px;
  height: 136px;
}

#articles-link {
  @extend %fixed-background;
  background-image: url("./../../media/articles.png");
  width: 220px;
  height: 50px;
}

#projects-link {
  @extend %fixed-background;
  background-image: url("./../../media/projects.png");
  width: 233px;
  height: 50px;
}

#header>nav>ul {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;

  // align
  justify-content: flex-start;
  align-items: center;

  position: relative;
  width: 100%;
  height: auto;
  margin: 0 auto;
  padding: 0;

  &>li {
    width: 100%;
    margin:0;
    padding: 0;
    text-indent: 0;
    list-style-type: none;
    display: inline-block;

    &:first-child {
      margin-bottom: $standard-space;
    }

    &+li {
      margin-top: $standard-space;

      &>a {
        @include link-pointer();
      }
    }

    &:first-child>a::before{
      @include floating-icon(250px, 220px, left, $tweak-x: 116px);
      top: 0;
      background-position: 50% 0;
      margin-top: 0;

      z-index: -1;
      background-image: url("./../../media/face.png");
    }

    &>a {
      display: block;
      position: relative;
      margin: 0 auto;
    }
  }
}

@include breakpoint(sm) {
  #header>nav>ul {
    height: 136px;
    >li {
      width: auto;

      &:first-child {
        width: auto;
        margin-right: 0;
        &>a {
          margin: 0 math.div($menu-margin, 2) auto auto;
        }
      }

      &+li {
        width: auto;
        margin-left: 0;
        &>a {
          margin: 0 auto auto $menu-margin;
        }
      }
    }
  }
}

@include breakpoint(md) {
  #header>nav>ul {
    -ms-flex-direction: row;
    flex-direction: row;
    &>li {
      &:first-child{
        margin-left: 100px;
        &>a{
          margin-right: 0;
        }
      }
      &+li {
        &>a {
          margin: 0 auto auto $menu-margin*1.5;
        }
      }
    }
  }
}

@include breakpoint(lg) {
  #header>nav>ul {
    justify-content: flex-end;
    &>li {
      &:first-child {
        margin-left: $header-margin;
        margin-right: auto;
      }
      &+li {
      }
      &:last-child {
        margin-right: $header-margin;
      }
    }
  }
}
