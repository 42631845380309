@use "sass:math";
@use "sass:color";

// Colours

$color-light: white;
$color-dark: black;
$color-danger: #ff4f4f;

$color-primary: $color-light;
$color-secondary: #00d0ff;

$color-off-primary: color.invert($color-primary, 90%);
$color-off-secondary: color.invert($color-secondary, 90%);

// Sizing
$base-size: 16px;
$standard-space: math.div($base-size, 2);
$small-space: math.div($standard-space, 2);
$grid-gutter: math.div($standard-space, 2);

// Borders

$border-thickness: 4px;
$border-radius: 8px;

// Breakpoints

$breakpoints: (
  sm: 768px,
  md: 1000px,
  lg: 1440px
);


// Transitions

$transitions: (
  basic: all .3s ease
);

// Frames

$card-color: darken($color-light, 5%);

// Typography
$base-text-size: 16px;
$text-size: 1.7rem;
$smaller-text-size: 0.85rem;
$larger-text-size: 1.25rem;
$underline-thickness: 2px;

$title-size: 2.5rem;
$subtitle-size: 2.0rem;
$section-size: 1.9rem;

$text-color: $color-primary;
$text-color-darker: darken($text-color, 15%);
$text-color-dark: darken($text-color, 50%);

$link-color: $color-secondary;
$link-hover-color: lighten($link-color, 10%);
$link-visited-color: darken($link-color, 10%);