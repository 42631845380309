// html tags

html {
  font-size: $base-text-size;
  font-family: "SH Pinscher", sans-serif;
  color: $text-color;
  text-align: left;
}

html, body {
  height: 100%;
}

body {
  background: black url("./../media/background.png") center top no-repeat fixed;
}

a {
  text-decoration: none;
  text-decoration-color: inherit;
  text-decoration-thickness: $underline-thickness;
  color: $link-color;

  &:visited {
    color: $link-visited-color;
  }

  &:hover {
    color: $link-hover-color;
  }

  &>p:hover, &>h2:hover {
    color: $link-hover-color;
  }

  &>p, &>h2 {
    color: $text-color;
  }
}

// Text

h1 {
  font-size: $title-size;
}

h2 {
  font-size: $subtitle-size;
}

h3, h4, h5 {
  color: $text-color-darker;
  font-size: $section-size;
}

p, p>span {
  font-size: $text-size;
}

p>code {
  display: inline-block;
  font-size: 1rem;
  padding: 4px 2px;
  border-radius: 4px;
  background-color: $color-off-primary;
}

ul {
  font-size: $text-size;

  &>li {
    list-style-type: square;
  }
}

kbd {
  display: inline-block;
  vertical-align: middle;
  color: black;
  font-family: "SH Pinscher", sans-serif;
  font-size: $larger-text-size;
  background-color: white;
  border: solid 1px gray;
  border-radius: 6px;
  padding: 4px 8px;
  line-height: 1rem;
}

// utility

.hidden {
  display: none;
}

.accessibility {
  border: 0;
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  word-wrap: normal !important;

  &:hover {
    background-color: #eee;
    clip: auto !important;
    clip-path: none;
    color: #444;
    display: block;
    font-size: 1em;
    height: auto;
    left: 5px;
    line-height: normal;
    padding: 15px 23px 14px;
    text-decoration: none;
    top: 5px;
    width: auto;
    z-index: 100000;
  }
}

// react containers

#root {
  height: 100%;
}